import React, { useRef, Suspense } from "react"
import * as THREE from "three"
import { Canvas, extend, useFrame, useLoader } from "@react-three/fiber"
import { shaderMaterial } from "@react-three/drei"
import vertexShader from "../../shaders/v.glsl"
import fragmentShader from "../../shaders/f.glsl"
import Media from "react-media"

// import dfyBannerBlue from "../../images/dfy-banner-blue.png"
// import dfyBannerRed from "../../images/dfy-banner-red.png"
import banner from "../../images/dfy-banner-blue-bold.png"
// import banner from "../../images/dfy-banner-teal.png"
// import glsl from "babel-plugin-glsl/macro"
// import glsl from "glsl"
const WaveShaderMaterial = shaderMaterial(
  // Uniform
  {
    uTime: 0,
    uColor: new THREE.Color(0.0, 0.0, 0.0),
    uTexture: new THREE.Texture(),
  },
  // Vertex Shader
  vertexShader,
  // Fragment Shader
  fragmentShader
)

extend({ WaveShaderMaterial })

const Banner = () => {
  // console.log("fragmentShader", fragmentShader)
  // console.log("vertexShader", vertexShader)
  const ref = useRef()
  // const smallRef = useRef()
  // const mediumRef = useRef()
  // const laptopRef = useRef()
  // const desktopRef = useRef()
  const largeDesktopRef = useRef()
  useFrame(({ clock }) => (ref.current.uTime = clock.getElapsedTime()))
  // useFrame(({ clock }) => {
  // if (smallRef?.current) {
  //   smallRef.current.uTime = clock.getElapsedTime()
  // } else if (mediumRef?.current) {
  //   mediumRef.current.uTime = clock.getElapsedTime()
  // } else if (laptopRef?.current) {
  //   laptopRef.current.uTime = clock.getElapsedTime()
  // } else if (desktopRef?.current) {
  //   desktopRef.current.uTime = clock.getElapsedTime()
  // } else if (largeDesktopRef?.current) {
  //   largeDesktopRef.current.uTime = clock.getElapsedTime()
  // }
  // })
  const [image] = useLoader(THREE.TextureLoader, [
    // "https://images.unsplash.com/photo-1604011092346-0b4346ed714e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1534&q=80",
    // "/banner.png",
    // bannerImg,
    // dfyBannerBlue,
    banner,
  ])

  return (
    <Media
      queries={{
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 1199px)",
        laptop: "(min-width: 1200px)",
        desktop: "(min-width: 1600px)",
        largeDesktop: "(min-width: 1920px)",
      }}
    >
      {matches => (
        <>
          <mesh scale={matches.laptop ? 3.5 : 3}>
            <planeBufferGeometry args={[0.4, 0.6, 16, 16]} />
            <waveShaderMaterial uColor={"black"} ref={ref} uTexture={image} />
          </mesh>
        </>
      )}
    </Media>
  )
}

export default Banner
