import "../styles/done-for-you.scss"
import React, { useState, useEffect, Suspense } from "react"
import { Helmet } from "react-helmet"
import { Canvas } from "@react-three/fiber"
import Header from "../components/Header"
// import Header from "../components/Nav"
import ActOne from "../components/DFY/ActOne"
import ActTwo from "../components/DFY/ActTwo"
import ActThree from "../components/DFY/ActThree"
// import ActFour from "../components/DFY/ActFour"
import ActFive from "../components/DFY/ActFive"
import Footer from "../components/Footer"
// import favicon from "../images/favicon.ico"
import favicon from "../images/favicon.png"

// import AuthGate from "../components/Auth/AuthGate"
// import { useAuth } from "../lib/firebase/firebaseCentral"
import GoTrue from "gotrue-js"
import firebase from "gatsby-plugin-firebase"

export default function InHouse() {
  const [deviceRatio, setDeviceRatio] = useState(2)
  // const { userLoading, user, profile } = useAuth()
  const [user, setUser] = useState(null)
  const [userLoading, setUserLoading] = useState(false)
  const [identity, setIdentity] = useState(null)
  useEffect(() => {
    const auth = new GoTrue({
      APIUrl: "https://www.berserkers.dev/.netlify/identity",
      audience: "",
      // setCookie(optional): set to be false by default. If you wish to implement the remember me functionality, set the value to be true.
      setCookie: false,
    })
    // console.log("auth", auth);
    setIdentity(auth)
  }, [])

  useEffect(() => {
    if (identity?.currentUser()?.email) {
      if (
        identity &&
        identity.currentUser &&
        identity.currentUser() &&
        identity.currentUser().email
      ) {
        const e = identity.currentUser().email
        // console.log("e", e);
        getUser(e)
      }

      async function getUser(e) {
        // console.log("e", e);
        const userRef = firebase
          .firestore()
          .collection("users")
          // .doc(identity.currentUser().email);
          .doc(e)
        const userDoc = await userRef.get()
        const userData = await userDoc.data()
        setUser(userData)
      }
    }
  }, [identity])

  // console.log("userLoading", userLoading)
  // console.log("profile", profile)

  useEffect(() => {
    if (window) {
      setDeviceRatio(window.devicePixelRatio)
    }
    // document.documentElement.style.setProperty(
    //   "--scrollbar-width",
    //   window.innerWidth - document.documentElement.clientWidth + "px"
    // )
  }, [])
  return (
    <>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Done For You</title>
        <link rel="icon" href={favicon} />
      </Helmet>

      <div className="dfy">
        <ActOne />
        <ActTwo />
        <div className="canvas">
          <Canvas
            // shadowMap
            // colorManagement
            gl={{ antialias: false }}
            pixelRatio={deviceRatio || 2}
            dpr={2}
            // pixelRatio={}
            // camera={{ position: [0, 2, 10], fov: 60 }}
            // camera={{ position: [0, 0, 8], fov: 60 }}
            camera={{ position: [0, 0, 2.25], fov: 60 }}
            // orthographic
          >
            <Suspense fallback={null}>
              <ActThree />
            </Suspense>
          </Canvas>
        </div>
        {/* <ActFour /> */}
        {<ActFive userLoading={userLoading} user={user} />}
        <Footer />
      </div>
    </>
  )
}
