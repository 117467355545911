import React, { useEffect } from "react"
import gsap from "gsap"

const ActOne = () => {
  useEffect(() => {
    gsap.set(".dfy-heading-letter", { autoAlpha: 1 })
    gsap.set(".subheading", { autoAlpha: 1 })

    gsap.from(".dfy-heading-letter", {
      autoAlpha: 0,
      duration: 1,
      ease: "power3.inOut",
      stagger: {
        // from: "center",
        from: "end",
        amount: 0.5,
      },
    })

    gsap.from(".subheading", {
      autoAlpha: 0,
      duration: 1,
      delay: 0.5,
      ease: "power3.inOut",
    })
  }, [])

  return (
    <div className="dfy-one">
      <div className="dfy-one__heading">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.05 25.41">
          <path
            className="dfy-heading-letter"
            d="M225.45 298.84a1.14 1.14 0 0 1 1.16-1.17 1.17 1.17 0 1 1 0 2.33 1.14 1.14 0 0 1-1.16-1.16Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_3"
            data-name="Layer 3"
          />
          <path
            className="dfy-heading-letter"
            d="M218.63 296.37c0-.7-.42-1-.87-1a.9.9 0 0 0-.92 1v3.51h-2.07v-6.24h2.07v.72a2 2 0 0 1 1.7-.84 1.92 1.92 0 0 1 1.71.93 2.42 2.42 0 0 1 2-.93c1.33 0 2.29.84 2.29 2.79v3.57h-2.08v-3.51c0-.7-.41-1-.86-1a.89.89 0 0 0-.93 1v3.51h-2.06Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_4"
            data-name="Layer 4"
          />
          <path
            className="dfy-heading-letter"
            d="M206.92 296.76a3 3 0 0 1 3-3.24 2.15 2.15 0 0 1 1.73.71v-.59h2.07v6.24h-2.07v-.59a2.15 2.15 0 0 1-1.73.71 3 3 0 0 1-3-3.24Zm3.4 1.41a1.41 1.41 0 0 0 0-2.81 1.41 1.41 0 0 0 0 2.81Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_5"
            data-name="Layer 5"
          />
          <path
            className="dfy-heading-letter"
            d="M200.14 296.77a3.12 3.12 0 0 1 3.2-3.25c1.59 0 3.49 1.09 3.06 4h-4.23a1.54 1.54 0 0 0 1.51.76 2.36 2.36 0 0 0 1.41-.41l.92 1a3.37 3.37 0 0 1-5.87-2.11Zm4.44-.67a1.16 1.16 0 0 0-1.21-.85 1.29 1.29 0 0 0-1.27.85Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_6"
            data-name="Layer 6"
          />
          <path
            className="dfy-heading-letter"
            d="M196.29 297.79v-2.52h-.95v-1.63h.95v-1.59h2.07v1.59h1.32v1.63h-1.32v2.21c0 .29.17.65.76.65a2.13 2.13 0 0 0 .59-.1v1.57a1.86 1.86 0 0 1-1.27.35 1.93 1.93 0 0 1-2.15-2.16Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_7"
            data-name="Layer 7"
          />
          <path
            className="dfy-heading-letter"
            d="M188.74 296.77a3.12 3.12 0 0 1 3.2-3.25c1.59 0 3.49 1.09 3.06 4h-4.23a1.54 1.54 0 0 0 1.51.76 2.36 2.36 0 0 0 1.41-.41l.92 1a3.37 3.37 0 0 1-5.87-2.11Zm4.44-.67a1.16 1.16 0 0 0-1.21-.85 1.29 1.29 0 0 0-1.27.85Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_8"
            data-name="Layer 8"
          />
          <path
            className="dfy-heading-letter"
            d="M184 293.64h2.08v1.71a1.9 1.9 0 0 1 2.37-1.77v2a2.14 2.14 0 0 0-.45-.05 1.8 1.8 0 0 0-1.92 1.95v2.42H184Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_9"
            data-name="Layer 9"
          />
          <path
            className="dfy-heading-letter"
            d="M179 291.09a2.46 2.46 0 0 1 1.27.24v1.59a1.33 1.33 0 0 0-.66-.09.76.76 0 0 0-.86.81H183v6.24h-2.07v-4.61h-2.12v4.61h-2.08v-4.61h-.85v-1.63h.85v-.07a2.23 2.23 0 0 1 2.27-2.48Zm1.87.27H183v1.79h-2.07Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_10"
            data-name="Layer 10"
          />
          <path
            className="dfy-heading-letter"
            d="M170.73 291.36h2.07v8.52h-2.07Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_11"
            data-name="Layer 11"
          />
          <path
            className="dfy-heading-letter"
            d="M162.88 296.76a3 3 0 0 1 3-3.24 2.15 2.15 0 0 1 1.73.71v-.59h2.07v6.24h-2.08v-.59a2.15 2.15 0 0 1-1.73.71 3 3 0 0 1-2.99-3.24Zm3.4 1.41a1.41 1.41 0 0 0 0-2.81 1.41 1.41 0 0 0 0 2.81Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_12"
            data-name="Layer 12"
          />
          <path
            className="dfy-heading-letter"
            d="M159 297.79v-2.52h-.95v-1.63h.95v-1.59h2.07v1.59h1.32v1.63h-1.29v2.21c0 .29.17.65.76.65a2.13 2.13 0 0 0 .59-.1v1.57a1.86 1.86 0 0 1-1.27.35 1.93 1.93 0 0 1-2.18-2.16Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_13"
            data-name="Layer 13"
          />
          <path
            className="dfy-heading-letter"
            d="M155.25 291.36h2.07v1.79h-2.07Zm0 2.28h2.07v6.24h-2.07Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_14"
            data-name="Layer 14"
          />
          <path
            className="dfy-heading-letter"
            d="M149 302v-1.64a3.1 3.1 0 0 0 1.41.29c1.17 0 1.71-.56 1.71-1.16v-.21H152a2 2 0 0 1-1.66.71 3.25 3.25 0 0 1 0-6.48 2 2 0 0 1 1.66.71h.08v-.59h2.07v5.68c0 2.53-2 3.05-3.38 3.05A3.62 3.62 0 0 1 149 302Zm1.81-3.84a1.41 1.41 0 0 0 0-2.81 1.41 1.41 0 0 0 0 2.81Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_15"
            data-name="Layer 15"
          />
          <path
            className="dfy-heading-letter"
            d="M144.57 291.36h2.08v1.79h-2.08Zm0 2.28h2.08v6.24h-2.08Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_16"
            data-name="Layer 16"
          />
          <path
            className="dfy-heading-letter"
            d="M141.44 299.88v-.59a2.15 2.15 0 0 1-1.73.71 3.25 3.25 0 0 1 0-6.48 2.15 2.15 0 0 1 1.73.71v-2.87h2.07v8.52Zm-1.32-1.71a1.41 1.41 0 0 0 0-2.81 1.41 1.41 0 0 0 0 2.81Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_17"
            data-name="Layer 17"
          />
          <path
            className="dfy-heading-letter"
            d="M185.09 279.24h2.08V281a1.9 1.9 0 0 1 2.37-1.77v2a2.14 2.14 0 0 0-.45-.05 1.8 1.8 0 0 0-1.92 1.95v2.42h-2.08Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_18"
            data-name="Layer 18"
          />
          <path
            className="dfy-heading-letter"
            d="M184 285.48h-2v-.73a2.1 2.1 0 0 1-1.78.85c-1.26 0-2.31-.78-2.31-2.69v-3.67h2.07v3.31c0 .65.31 1.22 1 1.22s1-.57 1-1.22v-3.31H184Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_19"
            data-name="Layer 19"
          />
          <path
            className="dfy-heading-letter"
            d="M170.45 282.36a3.33 3.33 0 1 1 3.34 3.24 3.2 3.2 0 0 1-3.34-3.24Zm3.34 1.38a1.38 1.38 0 1 0-1.3-1.38 1.29 1.29 0 0 0 1.3 1.38Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_20"
            data-name="Layer 20"
          />
          <path
            className="dfy-heading-letter"
            d="M160.82 279.24h2.07v.59a2.13 2.13 0 0 1 1.73-.71 3.25 3.25 0 0 1 0 6.48 2.13 2.13 0 0 1-1.73-.71v2.87h-2.07Zm3.39 4.53a1.41 1.41 0 0 0 0-2.81 1.41 1.41 0 0 0 0 2.81Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_21"
            data-name="Layer 21"
          />
          <path
            className="dfy-heading-letter"
            d="M157.69 277h2.07v1.79h-2.07Zm0 2.28h2.07v6.24h-2.07Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_22"
            data-name="Layer 22"
          />
          <path
            className="dfy-heading-letter"
            d="M156.61 285.48h-2.07v-.73a2.09 2.09 0 0 1-1.77.85c-1.26 0-2.32-.78-2.32-2.69v-3.67h2.08v3.31c0 .65.32 1.22 1 1.22s1-.57 1-1.22v-3.31h2.08Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_23"
            data-name="Layer 23"
          />
          <path
            className="dfy-heading-letter"
            d="M147.33 287.76v-2.87a2.13 2.13 0 0 1-1.73.71 3.25 3.25 0 0 1 0-6.48 2.13 2.13 0 0 1 1.73.71v-.59h2.07v8.52Zm-1.32-4a1.41 1.41 0 1 0-1.36-1.41 1.33 1.33 0 0 0 1.35 1.42Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_24"
            data-name="Layer 24"
          />
          <path
            className="dfy-heading-letter"
            d="M137 277h5v1.94h-2.92v1.34h2.43v1.91h-2.43v1.4h3v1.93H137Z"
            transform="translate(-136.72 -276.96)"
            id="Layer_2"
            data-name="Layer 2"
          />
        </svg>

        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.76 82.95">
          <path
            className="dfy-heading-letter"
            d="M645.71 371.34a4.37 4.37 0 1 1 4.36 4.37 4.25 4.25 0 0 1-4.36-4.37Z"
            transform="translate(-395.87 -300.86)"
            id="dot"
          />
          <path
            className="dfy-heading-letter"
            d="m625.73 369 6.11-1.67a3 3 0 0 0 2.93 2.39c1.08 0 2-.5 2-1.49 0-.49-.27-1.12-1.49-1.48l-3.42-1.26c-3.86-1.35-5.53-3.92-5.53-6.93 0-4.5 4.09-7.11 8.82-7.11s7.92 2.61 8.68 6.52l-6.07 1.49c-.5-1.8-1.62-2.07-2.3-2.07a1.43 1.43 0 0 0-1.62 1.3c0 .54.32 1.17 1.67 1.53l3.19 1.17c2.25.72 5.72 2.48 5.72 6.84 0 4.73-4 7.52-9.5 7.52-4.83-.04-8.47-2.21-9.19-6.75Z"
            transform="translate(-395.87 -300.86)"
            id="efforts"
          />
          <path
            className="dfy-heading-letter"
            d="M623.61 368.33v5.89c-.9 1-3 1.3-4.77 1.3-4.68 0-8.05-2.25-8.05-8.09V358h-3.56v-6.12h3.56v-6h7.78v6h4.95V358h-4.95v8.28c0 1.07.63 2.43 2.84 2.43a7.4 7.4 0 0 0 2.2-.38Z"
            transform="translate(-395.87 -300.86)"
            id="effort"
          />
          <path
            className="dfy-heading-letter"
            d="M605.52 351.63v7.43a6.85 6.85 0 0 0-1.71-.18c-4.68 0-7.2 3.06-7.2 7.29v9.08h-7.78v-23.39h7.78v6.39c.77-3.87 3-6.84 7.16-6.84a6.21 6.21 0 0 1 1.75.22Z"
            transform="translate(-395.87 -300.86)"
            id="effor"
          />
          <path
            className="dfy-heading-letter"
            d="M561 363.56c0-8 6.34-12.15 12.51-12.15S586 355.59 586 363.56s-6.34 12.15-12.46 12.15S561 371.52 561 363.56Zm17.32 0a4.85 4.85 0 1 0-4.81 5.17 4.86 4.86 0 0 0 4.83-5.17Z"
            transform="translate(-395.87 -300.86)"
            id="effo"
          />
          <path
            className="dfy-heading-letter"
            d="M555.62 351.86h5.71V358h-5.71v17.27h-7.79V358h-8v17.27h-7.79V358h-3.19v-6.12h3.19v-.72c0-7.79 5.49-8.87 8.87-8.87a9 9 0 0 1 4.63.9v6a5.08 5.08 0 0 0-2.47-.36c-2.34 0-3.2 1.35-3.24 3.06h8v-.72c0-7.79 5.54-8.87 8.91-8.87a8.93 8.93 0 0 1 4.59.9v6a5 5 0 0 0-2.43-.36c-2.38-.04-3.24 1.31-3.28 3.02Z"
            transform="translate(-395.87 -300.86)"
            id="eff"
          />
          <path
            className="dfy-heading-letter"
            d="M527.4 366.57h-15.88c.94 1.89 3 2.84 5.67 2.84a9 9 0 0 0 5.31-1.53l3.42 3.64a12 12 0 0 1-9.59 4.19c-6.57 0-12.42-4.42-12.42-12.11a11.71 11.71 0 0 1 12-12.19c5.96 0 13.09 4.09 11.49 15.16Zm-16.15-5.49h9.31a4.4 4.4 0 0 0-4.54-3.19 4.89 4.89 0 0 0-4.77 3.19Z"
            transform="translate(-395.87 -300.86)"
            id="e"
          />
          <path
            className="dfy-heading-letter"
            d="M494.15 351.63v7.43a6.9 6.9 0 0 0-1.71-.18c-4.68 0-7.2 3.06-7.2 7.29v9.08h-7.79v-23.39h7.79v6.39c.76-3.87 3-6.84 7.15-6.84a6.28 6.28 0 0 1 1.76.22Z"
            transform="translate(-395.87 -300.86)"
            id="your"
          />
          <path
            className="dfy-heading-letter"
            d="M473.49 351.86v23.39h-7.78v-2.74a7.85 7.85 0 0 1-6.71 3.2c-4.68 0-8.64-2.93-8.64-10.08v-13.77h7.79v12.42c0 2.43 1.17 4.54 3.82 4.54s3.74-2.11 3.74-4.54v-12.42Z"
            transform="translate(-395.87 -300.86)"
            id="you"
          />
          <path
            className="dfy-heading-letter"
            d="M422.55 363.56c0-8 6.35-12.15 12.51-12.15s12.47 4.18 12.47 12.15-6.35 12.15-12.47 12.15-12.51-4.19-12.51-12.15Zm17.33 0a4.85 4.85 0 1 0-4.82 5.17 4.86 4.86 0 0 0 4.82-5.17Z"
            transform="translate(-395.87 -300.86)"
            id="yo"
          />
          <path
            className="dfy-heading-letter"
            d="m422.64 351.86-13.32 31.94h-7.6l3.82-8.77-9.67-23.17h7.92l5.49 14.31 5.44-14.31Z"
            transform="translate(-395.87 -300.86)"
            id="y"
          />
          <path
            className="dfy-heading-letter"
            d="M671.4 324.57h-15.88c.94 1.89 3 2.84 5.67 2.84a9 9 0 0 0 5.31-1.53l3.42 3.64a12 12 0 0 1-9.59 4.19c-6.57 0-12.42-4.42-12.42-12.11a11.71 11.71 0 0 1 12-12.19c5.96 0 13.09 4.09 11.49 15.16Zm-16.15-5.49h9.31a4.4 4.4 0 0 0-4.54-3.19 4.89 4.89 0 0 0-4.77 3.19Z"
            transform="translate(-395.87 -300.86)"
            id="supercharge"
          />
          <path
            className="dfy-heading-letter"
            d="M645.07 309.86v21.28c0 9.49-7.51 11.47-12.68 11.47-2.39 0-5.22-.31-6.8-1.35v-6.16a11.65 11.65 0 0 0 5.31 1.08c4.37 0 6.39-2.12 6.39-4.37v-.76H637c-.18.41-2.29 2.66-6.25 2.66-5.27 0-11.16-4-11.16-12.15s5.89-12.15 11.16-12.15c4 0 6.07 2.25 6.25 2.65h.27v-2.2Zm-7.6 11.7a4.94 4.94 0 0 0-5.08-5.27 5.27 5.27 0 0 0 0 10.53 4.93 4.93 0 0 0 5.08-5.26Z"
            transform="translate(-395.87 -300.86)"
            id="supercharg"
          />
          <path
            className="dfy-heading-letter"
            d="M618.57 309.63v7.43a6.85 6.85 0 0 0-1.71-.18c-4.68 0-7.2 3.06-7.2 7.29v9.08h-7.78v-23.39h7.78v6.39c.77-3.87 3-6.84 7.16-6.84a6.21 6.21 0 0 1 1.75.22Z"
            transform="translate(-395.87 -300.86)"
            id="superchar"
          />
          <path
            className="dfy-heading-letter"
            d="M572.45 321.56c0-8.19 5.85-12.15 11.07-12.15 4.09 0 6.3 2.25 6.48 2.65h.13v-2.2h7.79v23.39h-7.79v-2.2H590c-.18.41-2.39 2.66-6.48 2.66-5.22 0-11.07-3.96-11.07-12.15Zm17.86 0a5.11 5.11 0 1 0-5.13 5.26 5 5 0 0 0 5.13-5.26Z"
            transform="translate(-395.87 -300.86)"
            id="supercha"
          />
          <path
            className="dfy-heading-letter"
            d="M569.61 319.49v13.76h-7.78v-12.41c0-2.43-1.17-4.55-3.83-4.55s-3.73 2.12-3.73 4.55v12.41h-7.79v-31.94h7.79v11.29a7.8 7.8 0 0 1 6.66-3.19c4.72 0 8.68 2.92 8.68 10.08Z"
            transform="translate(-395.87 -300.86)"
            id="superch"
          />
          <path
            className="dfy-heading-letter"
            d="M521 321.51c0-7.92 6.25-12.1 12.55-12.1a11.49 11.49 0 0 1 10.13 5.76l-5.68 3.69a5.7 5.7 0 0 0-4.5-2.48 5.18 5.18 0 0 0 0 10.35 5.72 5.72 0 0 0 4.5-2.47l5.63 3.69a11.68 11.68 0 0 1-10.13 5.76c-6.28 0-12.5-4.19-12.5-12.2Z"
            transform="translate(-395.87 -300.86)"
            id="superc"
          />
          <path
            className="dfy-heading-letter"
            d="M519.93 309.63v7.43a6.85 6.85 0 0 0-1.71-.18c-4.68 0-7.2 3.06-7.2 7.29v9.08h-7.78v-23.39H511v6.39c.77-3.87 3-6.84 7.16-6.84a6.21 6.21 0 0 1 1.77.22Z"
            transform="translate(-395.87 -300.86)"
            id="super"
          />

          <path
            className="dfy-heading-letter"
            d="m396.32 325.65 6.39-3c.9 1.85 2.34 3.92 5.13 3.92 1.84 0 3.51-.9 3.51-3.06 0-1.53-.72-2.3-3.56-3.42l-2-.77c-4.14-1.66-8.32-4-8.32-9.4 0-5.67 4.77-9.09 10.39-9.09a11.6 11.6 0 0 1 10.76 6.84l-5.83 3.33c-1.4-2.34-2.93-3.2-4.59-3.2s-2.93.72-2.93 2.25c0 1.17.68 2.12 3.92 3.38l2.16.85c5.89 2.39 8.23 5 8.23 9.32 0 6.88-6.16 10.08-11.61 10.08-5.67.03-10.44-3.35-11.65-8.03Z"
            transform="translate(-395.87 -300.86)"
            id="s"
          />
          <path
            className="dfy-heading-letter"
            d="M445.55 309.86v23.39h-7.79v-2.74a7.83 7.83 0 0 1-6.7 3.2c-4.68 0-8.64-2.93-8.64-10.08v-13.77h7.78v12.42c0 2.43 1.17 4.54 3.83 4.54s3.73-2.11 3.73-4.54v-12.42Z"
            transform="translate(-395.87 -300.86)"
            id="su"
          />
          <path
            className="dfy-heading-letter"
            d="M475 321.56c0 8.19-5.85 12.15-11.12 12.15-4.05 0-6.25-2.25-6.43-2.66h-.14v10.75h-7.78v-31.94h7.78v2.2h.14c.18-.4 2.38-2.65 6.43-2.65 5.25 0 11.12 3.96 11.12 12.15Zm-7.65 0a5.11 5.11 0 1 0-5.09 5.26 5 5 0 0 0 5.07-5.26Z"
            transform="translate(-395.87 -300.86)"
            id="sup"
          />
          <path
            className="dfy-heading-letter"
            d="M500.18 324.57h-15.89c.95 1.89 3 2.84 5.67 2.84a9 9 0 0 0 5.31-1.53l3.42 3.64a11.92 11.92 0 0 1-9.58 4.19c-6.57 0-12.42-4.42-12.42-12.11a11.71 11.71 0 0 1 12-12.19c5.95 0 13.11 4.09 11.49 15.16ZM484 319.08h9.32a4.41 4.41 0 0 0-4.55-3.19 4.89 4.89 0 0 0-4.77 3.19Z"
            transform="translate(-395.87 -300.86)"
            id="supe"
          />
        </svg> */}
      </div>
      <div className="dfy-one__subheading subheading">
        We'll get you there, fast.
      </div>
    </div>
  )
}

export default ActOne
